<template>
    <div>
        <draggable v-model="Clauses" @start="drag=true" @end="drag=false" @change="recalculateIndex()">
            <LcaClause
                :clause="clause"
                :update-mode="updateMode"
                :idx="clause.index"
                v-bind:key="clause.index"
                v-for="clause in Clauses"
            ></LcaClause>
        </draggable>
        <v-divider class="mt-0"></v-divider>
        <v-layout row>
            <v-flex class="my-0 pt-0 col-8">
                <v-select
                        :items="defaultClauses"
                        :value="newStandardClause"
                        clearable
                        dense
                        hide-details
                        item-text="Clause.title"
                        item-value="Clause.id"
                        solo
                        v-model="newStandardClause"
                >
                </v-select>
            </v-flex>
            <v-flex class="my-0 pt-0 col-lg-2 col-4">
                    <v-btn class="auto-width" @click="addNewClause('standard')"><v-icon>add</v-icon> {{ $t('message.addStandardClause') }}</v-btn>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex class="my-0 pt-0 col-8">
                <v-text-field
                    :value="newCustomClause"
                    clearablele
                    dense
                    solo
                    hide-details
                    @change="newCustomClause = $event"
                ></v-text-field>
            </v-flex>
            <v-flex class="my-0 pt-0 col-lg-2 col-4">
                    <v-btn class="auto-width" @click="addNewClause('custom')"><v-icon>add</v-icon> {{ $t('message.addCustomClause') }}</v-btn>
            </v-flex>
        </v-layout>
        <AlertMissingValues
            :alert_message="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        >
        </AlertMissingValues>
    </div>
</template>

<script>
import AlertMissingValues from "Components/Appic/AlertMissingValues";
import LcaClause from "Components/Appic/LcaClause";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields"
import { mapFieldsPrefixed, mapMultiRowFieldsPrefixed } from 'Helpers/vuex-map-fields-prefixed'
import draggable from 'vuedraggable'

export default {
name: "LcaClauseList",
    components: {AlertMissingValues, draggable, LcaClause},
    props: ['updateMode'],
    data() {
        return {
            dialogs: {
                error:  false,
                error_message: ""
            },
            newStandardClause: null,
            newCustomClause: null
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('lcaclause',{
            Clauses: 'Clauses'
        },'statePrefix'),
        ...mapFields('lcaclause',{
            defaultClauses: 'defaultClauses'
        })
    },
    methods: {
        ...mapActions('lcaclause',{
            addClauseToList: 'addClauseToList'
        }),
        addNewClause(type){
            switch(type){
                case 'standard':
                    if(this.newStandardClause == null || this.newStandardClause.length == 0){
                        this.dialogs.error_message = this.$t('message.errors.noClauseSelected');
                        this.dialogs.error = true;
                    } else {
                        let payload = {
                            prefix: this.statePrefix,
                            value: this.defaultClauses.find(clause => clause.Clause.id == this.newStandardClause)?.Clause?.title
                        }
                        this.addClauseToList(payload)
                        this.newStandardClause = null
                    }
                    break
                case 'custom':
                    if(this.newCustomClause == null || this.newCustomClause.length == 0) {
                        this.dialogs.error_message = this.$t('message.errors.noClauseEntered');
                        this.dialogs.error = true;
                    } else {
                        let payload = {
                            prefix: this.statePrefix,
                            value: this.newCustomClause
                        }
                        this.addClauseToList(payload)
                        this.newCustomClause = null
                    }
                    break
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        recalculateIndex () {
            this.$store.commit('lcaclause/reCalculateListIndices',this.statePrefix)
        }
    },
    mounted() {},
    created() {
        // this.$store.commit('lcaclause/resetCurrentState')
    }
}
</script>

<style>
    .auto-width {
        width: auto !important;
        min-width: 0 !important;
    }
</style>