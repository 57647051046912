<template>
    <v-layout row class="py-0 my-0">
        <v-flex class="my-0 pt-0 col-8 d-flex flex-row">
            <v-icon class="page__grab-icon">mdi-arrow-all</v-icon>
            <v-text-field
                :value="clause.Clause.title"
                class="ml-3"
                dense
                solo
                hide-details
                @change="Clauses[idx].Clause.title = $event"
            ></v-text-field>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon @click="deleteThisClause(clause.index, clause.Clause.id)" v-on="on">
                        <v-icon color="grey">delete</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.deleteThisClause') }}</span>
            </v-tooltip>
        </v-flex>
    </v-layout>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    export default {
        name: "LcaClause",
        props: ['clause','idx','updateMode'],
        data() {
            return {}
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('lca',{
                Lca__id: 'Lca.id'
            }, 'statePrefix'),
            ...mapFieldsPrefixed('lcaclause',{
                Clauses: 'Clauses'
            }, 'statePrefix')
        },
        methods: {
            ...mapActions('lcaclause',{
                deleteClauseFromList: 'deleteClauseFromList',
                deleteClause: 'deleteClause',
                getClausesByLca: 'getClausesByLca'
            }),
            async deleteThisClause(itemIndex,itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteClauseAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deleteClauseFromList(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if (itemId != null) {
                            this.deleteClause({
                                lca_id: this.Lca__id,
                                clause_id: itemId
                            }).then(()=>{
                                //TODO reload update clause
                                let payload = {
                                    lca_id: this.Lca__id,
                                    prefix: this.statePrefix
                                }
                                this.getClausesByLca(payload)
                            })
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>